import Head from 'shared/Head';
import React from 'react';

export default function PageNotFound() {
  return (
    <>
      <Head title="Page Not Found" description="Page Not Found" />
			<h1>Page not found</h1>
    </>
  );
}
